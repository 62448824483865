.home{
   background-color: aliceblue;
   display: flex;
   flex-direction: row;
   justify-content: center;
   width: 100%;
   margin-top: 30px;
}
.home {
   /* Set a default flex-direction of row */
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   margin-top: 10px;
 }
 
 /* Apply styles for screens smaller than 768px */
 @media (max-width: 767px) {
   .home {
     /* Change the flex-direction to column */
     flex-direction: column;
   }
 
   /* Adjust the margin and width of the image in the .akphoto div */
   .akphoto img {
     max-width: 100%;
     margin-top: 1rem;
   }
 }
 
 /* Apply styles for screens larger than 1024px */
 @media (min-width: 1025px) {
   /* Add some space between each section of the home container */
   .home > * {
     margin: 0 2rem;
   }
 }
 